:root {
    --black: #00000080;
    --blue: #007bff;
    --dark-gray: #33403fcc;
    --green: #22cf1d;
    --light-blue: #007bff80;
    --light-gray: #213542;
    --white: #fff;
}

.mask-modal {
    max-width: 100%;
    top: 20px;
    width: 100%;
}

.mask-footer {
    align-items: center;
    background-color: var(--white);
    border-radius: 0 0 4px 4px;
    border-top: 1px solid var(--light-gray);
    box-shadow: 0 2px 4px var(--white);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
}

.mask-footer button {
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 15px;
}

.mask-footer button:first-child {
    background-color: var(--white);
    border: 1px solid var(--light-blue);
    color: var(--black);
    margin-right: 8px;
}

.mask-footer button:last-child {
    background-color: var(--blue);
    color: var('--white');
}

.mask-select {
    display: inline-block;
    position: relative;
    width: 120px;
}

.mask-select select {
    appearance: none;
    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--light-blue);
    color: var(--black);
    font-size: 14px;
    font-weight: 400;
    padding: 6px 12px;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.mask-select select:focus {
    border-color: var(--light-blue);
    box-shadow: 0 0 0 2px var(--green);
}

.mask-select::before {
    border-color: var(--dark-gray) transparent transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.mask-select::after {
    border-color: transparent transparent var(--dark-gray) transparent;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    content: '';
    display: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.mask-select select:focus + ::after {
    display: block;
}

.mask-select select:focus + ::before {
    display: none;
}
