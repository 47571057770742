:root {
    --orange: #ff8400;
    --blue: #007bff;
    --black: #00000086;
    --blue: #007bff;
    --brown: #5b4645e4;
    --gray: #a2a2a29e;
    --green: #22cf1d;
    --light-blue: #007bff80;
    --mid-gray: #ccc;
    --orange: #ff8400;
    --white: #fff;
}

.checkbox-container-item {
    align-content: baseline;
    align-items: center;
    display: grid;
    justify-content: center;
    justify-items: center;
    position: relative;
}

.checkbox {
    display: none;
}

.checkbox:checked + label {
    background: var(--mid-gray);
    background-color: var(--mid-gray);
    opacity: 0.51;
}

.checkbox-label {
    align-items: flex-end;
    box-shadow: inset 0px -20px 20px 3px var(--brown);
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: larger;
    font-weight: 700;
    height: 98%;
    justify-content: center;
    margin-top: 5px;
    position: absolute;
    text-align: center;
    width: 98%;
}

.checkbox-label.checked {
    background: var(--mid-gray);
    opacity: 0.1;
}

.counter {
    align-items: center;
    background-color: var(--gray);
    border-radius: 30px;
    border: 2px solid var(--white);
    display: flex;
    margin-top: 10%;
    position: relative;
    width: 120px;
    z-index: 2;
}

.counter-button {
    background-color: transparent;
    border: none;
    color: var(--black);
    cursor: pointer;
    flex: 1;
    font-size: 24px;
    outline: none;
}

.counter-dropdown {
    align-self: center;
    display: grid;
    justify-items: center;
}

.counter-value {
    background-color: var(--black);
    color: var(--white);
    font-size: 24px;
    text-align: center;
    width: 50px;
}

.modifier-button {
    align-items: center;
    background: var(--orange);
    border-radius: 50%;
    border: 2px solid var(--white);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    padding: 8px;
    transition: all 0.2s ease;
    user-select: none;
    width: 36px;
    -moz-border-radius: 50%;
    -moz-osx-font-smoothing: grayscale;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
}

.modifier-button.active {
    background-color: var(--light-blue);
    border-color: var(--green);
    transform: scale(0.95);
}

.modifier-button:hover {
    background-color: var(--blue);
    border-color: var(--orange);
    opacity: 0.8;
    transform: scale(1.05);
}

.modifier-dropdown {
    margin-top: 20%;
    position: relative;
    z-index: 2;
}

.modifier-dropdown option {
    background-color: var(--white);
    border: none;
    border-radius: 25px;
    padding: 20px;
}

.modifier-dropdown option:focus {
    outline: none;
}

.modifier-dropdown select {
    background-color: var(--white);
    border-radius: 30px;
    padding: 15px;
    position: relative;
}

.modifier-dropdown select:focus {
    outline: none;
}

.modifier-icon {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;
    margin: 10px 0;
    width: 100%;
}

.modifier-type {
    display: flex;
    justify-content: center;
    padding-top: 10%;
    position: relative;
    z-index: 3;
}

@media (orientation: landscape) {
    .checkbox-container {
        display: grid;
        grid-auto-columns: minmax(600px, 300px);
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 1fr);
        height: 90%;
        left: 50%;
        overflow-x: auto;
        position: absolute;
        top: 55%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .checkbox-container > div {
        background-color: var(--brown);
        box-sizing: border-box;
        width: 100%;
    }
}

@media ((orientation: landscape) and (max-width: 950px)) {
    .checkbox-container {
        grid-auto-columns: minmax(300px, 300px);
    }
}

@media (orientation: portrait) {
    .checkbox-container {
        bottom: 0;
        grid-auto-columns: minmax(200px, 200px);
        grid-auto-flow: row;
        grid-template-rows: repeat(auto-fill, minmax(200px, auto));
        height: 90%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        width: 100%;
    }
    .checkbox-container > div {
        background-color: var(--brown);
        box-sizing: border-box;
        height: 30%;
        width: 100%;
    }
}
