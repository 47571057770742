:root {
    --animate-one: #231557;
    --animate-two: #44107a;
    --animate-three: #ff1361;
    --animate-four: #fff800;
    --black: #00000080;
    --blue: #007bff;
    --bright-red: #ff1500;
    --brown: #3b302a;
    --cyan: #03e9f4;
    --dark-brown: #211919fe;
    --dark-gray: #33403fcc;
    --dark-green: #050801;
    --glow: #ccc;
    --green: #22cf1d;
    --light-blue: #007bff80;
    --light-brown: #5b4645;
    --light-gray: #213542;
    --orange: #ff8400;
    --red: #ff0000;
    --tan: #decabd;
    --vanta-black: #000;
    --white: #fff;
}

.animate-text {
    animation: text-clip 3s linear infinite;
    background-clip: border-box;
    background-clip: text;
    background-image: linear-gradient(-225deg,
            var(--animate-one) 0%,
            var(--animate-two) 29%,
            var(--animate-three) 67%,
            var(--animate-four) 100%);
    background-size: auto auto;
    background-size: 200% auto;
    color: var(--white);
    display: inline-block;
    font-size: 4em;
    text-fill-color: transparent;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-name {
    align-items: center;
    color: var(--white);
    display: flex;
    flex-direction: column;
    grid-area: Vibe;
    grid-column: 1/2;
    justify-content: flex-end;
}

.app-name h1 {
    align-self: center;
    font-family: 'Montserrat';
    font-size: 30vh;
    height: auto;
    margin: 0;
    position: absolute;
    width: auto;
}

.app-name p {
    color: var(--white);
    font-family: 'Montserrat';
    font-size: 2.25vw;
    font-weight: 700;
    margin: 0;
}

.blinking-text {
    align-items: center;
    animation: blink 8s linear infinite;
    display: flex;
}

.button-holder {
    align-content: center;
    height: 100%;
}

.carouselZ {
    bottom: 0;
    text-align: center;
}

.carousel-bottom-container {
    align-items: center;
    align-self: center;
    bottom: 0;
    display: contents;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    width: -webkit-fill-available;
}

.carousel-container {
    background-color: var(--gray);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.carousel-image {
    height: 90vh;
    justify-self: center;
    max-height: 1024px;
    max-width: 1024px;
    object-fit: cover;
    width: auto;
}

.carousel-keys {
    border-radius: 0.25em;
    bottom: 0;
    color: var(--white);
    cursor: pointer;
    padding: 10px;
    position: sticky;
    white-space: wrap;
    z-index: 3;
}

.carousel-keys-container {
    align-self: center;
    background-color: var(--brown);
    border-radius: 0.25em;
    bottom: 0;
    display: -webkit-box;
    max-width: -moz-fit-content;
    max-width: fit-content;
    overflow: visible;
    position: absolute;
    scrollbar-width: thin;
    width: 100%;
    z-index: 3;
}

.carousel-key-selected {
    background-color: var(--light-blue);
    font-weight: bold;
    display: flex;
    justify-content: center;
    position: relative;
}

.carousel-key-selected+.tool-menu,
.carousel-key-selected~.tool-menu {
    opacity: 1;
    pointer-events: auto;
}

.carousel-new-design {
    align-self: center;
    margin-bottom: 20px;
    order: 1;
    width: min-content;
    z-index: 5;
}

.design {
    grid-area: upload;
    grid-column: 1/2;
    height: 100%;
    position: relative;
}

.design button {
    font-size: 2.5vw;
}

.faulty-letter {
    animation: faulty-flicker 2s linear infinite;
    opacity: 0.5;
}

.glowing-btn {
    animation: border-flicker 2s linear infinite;
    background: none;
    border-radius: 0.45em;
    border: 0.15em solid var(--glow);
    box-shadow:
        inset 0px 0px 0.5em 0px var(--glow),
        0px 0px 0.5em 0px var(--glow);
    color: var(--glow);
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 2em;
    font-weight: 900;
    letter-spacing: 1em;
    padding: 0.35em 1em;
    perspective: 2em;
    position: relative;
    -moz-box-shadow:
        inset 0px 0px 0.5em 0px var(--glow),
        0px 0px 0.5em 0px var(--glow);
    -webkit-box-shadow:
        inset 0px 0px 0.5em 0px var(--glow),
        0px 0px 0.5em 0px var(--glow);
}

.glowing-btn::after {
    background-color: var(--glow);
    bottom: 0;
    box-shadow: 0 0 2em 0.2em var(--glow);
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 100ms linear;
    z-index: -1;
}

.glowing-btn:hover:after {
    background-color: var(--light-blue);
    box-shadow: 0 0 2em 0.2em var(--light-blue);
}

.glowing-btn::before {
    background: var(--glow);
    bottom: 0;
    content: '';
    filter: blur(1em);
    left: 0;
    opacity: 0.7;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
}

.glowing-btn:hover:before {
    background: var(--light-blue);
}

.glowing-btn:hover {
    animation: none;
    border: 0.15em solid var(--light-blue);
    box-shadow:
        inset 0px 0px 0.5em 0px var(--light-blue),
        0px 0px 0.5em 0px var(--light-blue);
    color: var(--white);
    text-shadow: none;
    -moz-box-shadow:
        inset 0px 0px 0.5em 0px var(--light-blue),
        0px 0px 0.5em 0px var(--light-blue);
    -webkit-box-shadow:
        inset 0px 0px 0.5em 0px var(--light-blue),
        0px 0px 0.5em 0px var(--light-blue);
}

.glowing-btn:hover .faulty-letter {
    animation: none;
    opacity: 1;
    text-shadow: none;
}

.glowing-btn:hover .glowing-txt {
    animation: none;
}

.glowing-btn:hover:after {
    opacity: 1;
}

.glowing-btn:hover:before {
    filter: blur(1.5em);
    opacity: 1;
}

.glowing-txt {
    animation: text-flicker 3s linear infinite;
    float: left;
    margin-right: -0.8em;
    text-shadow:
        0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow);
    -moz-text-shadow:
        0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow);
    -webkit-text-shadow:
        0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow);
}

.header {
    position: relative;
    align-items: center;
    background-color: var(--dark-brown);
    box-shadow: 0 2px 4px 0 var(--white);
    display: flex;
    height: 10%;
    justify-content: space-between;
    margin-top: 0px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.header-button {
    align-items: center;
    appearance: none;
    background: transparent;
    border-radius: 0.65em;
    border: none;
    color: var(--white);
    display: flex;
    font-family: 'Montserrat';
    font-size: 32px;
    height: 1.25em;
    justify-content: center;
    position: relative;
    right: 0.25em;
    text-align: center;
    z-index: 2;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.header-button:hover {
    background: var(--orange);
    box-shadow:
        0 0 5px var(--orange),
        0 0 25px var(--orange),
        0 0 50px var(--orange),
        0 0 200px var(--orange);
    color: var(--dark-green);
    -webkit-box-reflect: below 1px linear-gradient(transparent, var(--bright-red));
}

.header-button:disabled {
    background-color: var(--light-gray);
    color: var(--glow);
    cursor: not-allowed;
}

.header-button:disabled:hover {
    background: var(--light-gray);
    box-shadow: none;
    color: var(--glow);
    -webkit-box-reflect: none;
}

.header-button svg {
    height: 0.75em;
}

.header h1 {
    color: var(--white);
    font-size: 1.5em;
    margin: 0;
    padding-left: 10px;
}

.how-to-photo {
    align-items: center;
    align-self: center;
    background-image: url('../../../public/how-to-pic.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    grid-area: how-to-photo;
    height: 50%;
    position: relative;
}

.how-to-photo p {
    align-self: flex-end;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.774) 100%);
    border-radius: 10px;
    color: var(--white);
    font-size: 2vw;
    font-weight: 700;
    margin: 0;
    position: relative;
}

.how-to-span {
    align-self: flex-start;
    border-radius: 10px;
    color: var(--white);
    font-family: 'Montserrat';
    font-size: 2vw;
    font-weight: 700;
    grid-area: 1 / how-to-photo / 1 / transition-how-to;
    padding-top: 10%;
    position: relative;
}

.icon-circle {
    align-items: center;
    background-color: var(--orange);
    border-radius: 50%;
    border: 1px solid var(--light-gray);
    display: flex;
    height: 50px;
    justify-content: center;
    text-align: center;
    width: 50px;
}

.icon-circle:hover {
    background-color: var(--light-blue);
}

.intro-container {
    background-color: var(--brown);
    background-size: contain;
    background-repeat: round;
    bottom: 0;
    display: grid;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'Vibe how-to-photo transition-how-to'
        'upload how-to-photo transition-how-to';
    grid-template-columns: 1.5fr 0.75fr 0.75fr;
    grid-template-rows: 50% 50%;
    height: 90%;
    position: fixed;
    width: 100%;
}

.intro-span {
    align-self: flex-end;
    border-radius: 10px;
    color: var(--white);
    font-family: 'Montserrat';
    font-size: 2vw;
    font-weight: 700;
    grid-area: 2 / how-to-photo / 2 / transition-how-to;
    padding-bottom: 10%;
    position: relative;
}

.logo {
    height: 50px;
}

.money-box {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    padding: 0.45em;
    position: relative;
}

.overlay {
    align-items: center;
    background-color: var(--vanta-black);
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.paging-item {
    visibility: hidden;
}

.retry-button:hover {
    animation: spin 1s linear infinite;
}

.share-controls {
    bottom: 0;
    position: absolute;
    right: 0;
}

.slider-control-centerright {
    z-index: 2;
}

.slider-control-centerleft {
    z-index: 2;
}

.slide-image-container {
    display: grid;
    text-align: center;
    width: 100vw;
    z-index: 1;
}

.slide-image-container img.retrying {
    animation: blink 2s infinite ease-in-out;
}

.spinner {
    animation: spin 1s linear infinite;
    border-left-color: transparent;
    border-radius: 50%;
    border: 4px solid var(--blue);
    height: 5px;
    margin-left: 5px;
    width: 5px;
}

.tag-line {
    display: none;
}

.text {
    font-size: larger;
    padding: 10px;
}

.tip {
    margin-top: 20px;
    padding: 10px;
}

.tool-menu {
    align-content: center;
    flex-wrap: wrap;
    background-color: transparent;
    display: flex;
    height: auto;
    justify-content: center;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    transition: all 0.3s ease;
    width: auto;
    z-index: 2;
    bottom: 100%;
    top: auto;
    left: auto;
    right: auto;
}

.tool-menu button {
    align-self: center;
    background-color: var(--light-brown);
    border: none;
    color: var(--white);
    cursor: pointer;
    display: grid;
    font-size: 1.6em;
    padding: 0.5em;
    border: solid 1px var(--white);
    border-radius: 50%;
}

.tool-menu button:hover {
    opacity: 0.7;
    background-color: var(--light-blue);
}

.transition-how-to {
    align-items: center;
    align-self: center;
    background-image: url('../../../public/living-room-ex.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
    display: flex;
    grid-area: transition-how-to;
    height: 50%;
    position: relative;
}

.transition-how-to-bottom {
    display: none;
}

.transition-how-to-top {
    display: none;
}

.upload {
    align-items: center;
    bottom: 5%;
    box-sizing: border-box;
    color: var(--light-blue);
    display: flex;
    font-size: 2em;
    height: 50px;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50px;
    z-index: 1;
}

.upload:active {
    transform: translate(-50%, -50%) scale(0.9);
}

.upload:hover {
    background-color: var(--dark-gray);
    border-radius: 5px;
    transform: translate(-50%, -50%) scale(1.1);
}

.upload-button {
    align-content: center;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.upload-icon {
    background-color: transparent;
    border: none;
    border-radius: 0.45em;
    color: var(--white);
    height: 8vh;
    max-height: 80%;
    max-width: 100%;
    opacity: 0.9;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    width: 8vw;
}

.upload-icon:active {
    color: var(--light-blue);
    opacity: 1;
    transform: translateY(0);
}

.upload-icon:hover {
    color: var(--light-blue);
    opacity: 1;
    transform: translateY(-2px);
}

.welcome-screen {
    align-items: center;
    color: var(--light-blue);
    display: flex;
    flex-direction: column;
    height: 92.5vh;
    justify-content: start;
}

@keyframes blink {
    0% {
        opacity: 0.75;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        opacity: 0.75;
    }
}

@keyframes border-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 1;
    }

    4% {
        opacity: 0.1;
    }

    8% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

@keyframes faulty-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 0.1;
    }

    4% {
        opacity: 0.5;
    }

    19% {
        opacity: 0.5;
    }

    21% {
        opacity: 0.1;
    }

    23% {
        opacity: 1;
    }

    80% {
        opacity: 0.5;
    }

    83% {
        opacity: 0.4;
    }

    87% {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes text-clip {
    to {
        background-position: 200% center;
    }
}

@keyframes text-flicker {
    0% {
        opacity: 0.1;
    }

    2% {
        opacity: 1;
    }

    8% {
        opacity: 0.1;
    }

    9% {
        opacity: 1;
    }

    12% {
        opacity: 0.1;
    }

    20% {
        opacity: 1;
    }

    25% {
        opacity: 0.3;
    }

    30% {
        opacity: 1;
    }

    70% {
        opacity: 0.7;
    }

    72% {
        opacity: 0.2;
    }

    77% {
        opacity: 0.9;
    }

    100% {
        opacity: 0.9;
    }
}

@media only screen and (max-width: 600px) {
    .glowing-btn {
        font-size: 1em;
    }
}

@media (max-width: 767px) {
    .header {
        position: 10vh;
    }

    .header-button {
        font-size: 24px;
    }

    .price {
        font-size: 0.9em;
    }

    .tool-menu {
        bottom: 10vh;
    }

    .upload-icon {
        height: 6vh;
        width: 6vw;
    }
}

@media (min-width: 768px) {
    .upload-button {
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .carousel-image {
        max-height: 300px;
        width: auto;
    }

    .upload-button {
        margin: 0 auto;
    }

    .tool-menu {
        bottom: 20vh;
    }
}

@media screen and (orientation: landscape) and (min-device-width: 926px) and (max-device-width: 1080px) {
    .Vibe h1 {
        font-size: 30vh !important;
    }
}

@media (orientation: portrait) {
    .animate-text {
        font-size: 3em;
    }

    .button-holder {
        align-content: baseline;
        height: 100%;
    }

    .carousel-image {
        max-width: fit-content;
        width: 100%;
        height: auto;
    }

    .design {
        grid-area: upload;
        height: 100%;
        justify-self: center;
        position: relative;
    }

    .design button {
        font-size: 5.5vw;
        left: 0;
        top: 25%;
        transform: translate(0%, 0%);
    }

    .how-to-photo {
        background-image: url('../../../public/how-to-pic.jpg');
        background-position: center;
        background-size: cover;
        display: flex;
        grid-area: how-to-photo;
        height: 100%;
        justify-self: center;
        width: 80%;
    }

    .how-to-photo img {
        left: 0;
        position: relative;
        top: 0;
        transform: none;
        visibility: hidden;
    }

    .how-to-photo p {
        align-self: flex-end;
        background: linear-gradient(to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.774) 100%);
        border-radius: 10px;
        color: var(--white);
        font-size: 5vw;
        font-weight: 700;
        margin: 0;
        position: relative;
    }

    .how-to-span {
        display: none;
    }

    .intro-container {
        bottom: 0;
        height: 90%;
        display: grid;
        grid-template-areas: 'Vibe' 'tag-line' 'how-to-photo' 'how-to-text' 'transition-how-to' 'transition-text' 'upload';
        grid-template-columns: 1fr;
        grid-template-rows: 15% 5% 20% 5% 20% 5% 10%;
    }

    .app-name h1 {
        font-size: 10vh;
    }

    .intro-span {
        display: none;
    }

    .slider-control-centerleft {
        padding-top: 85%;
        width: 0%;
    }

    .slider-control-centerright {
        padding-top: 85%;
    }

    .tag-line {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: inherit;
        grid-area: tag-line;
        height: fit-content;
        justify-content: flex-start !important;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    .tag-line p {
        color: var(--white);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        font-family: 'Montserrat';
        font-size: 3vh !important;
        margin: 0;
        padding: 0;
    }

    .transition-how-to {
        background-image: url('../../../public/living-room-ex-portrait.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: grid;
        height: 100%;
        justify-content: center;
    }

    .transition-how-to p {
        display: flex;
    }

    .transition-how-to-top {
        align-self: flex-start;
        border-radius: 10px;
        color: var(--white);
        display: block;
        font-family: 'Montserrat';
        font-size: 5vw;
        font-weight: 700;
        grid-area: how-to-text;
        margin: 0;
        order: 1;
        position: relative;
    }

    .transition-how-to-bottom {
        align-self: flex-end;
        border-radius: 10px;
        color: var(--white);
        display: flex;
        font-family: 'Montserrat';
        font-size: 5vw;
        font-weight: 700;
        grid-area: transition-text;
        justify-content: center;
        margin: 0;
        order: 2;
        position: relative;
    }

    .upload {
        align-items: center;
        bottom: 5%;
        box-sizing: border-box;
        color: var(--light-blue);
        display: flex;
        font-size: 2em;
        height: 50px;
        justify-content: center;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 50px;
        z-index: 1;
    }

    .upload:active {
        transform: translate(-50%, -50%) scale(0.9);
    }

    .upload:hover {
        background-color: var(--dark-gray);
        border-radius: 5px;
        transform: translate(-50%, -50%) scale(1.1);
    }

    .Vibe {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: inherit;
        grid-area: Vibe;
        height: fit-content;
        justify-content: flex-start !important;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        position: initial;
        width: 100%;
    }

    .Vibe h1 {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 15vh !important;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    .Vibe p {
        display: none;
    }
}

@media screen and (orientation: portrait) and (max-device-height: 1080px) and (min-device-width: 800px) {
    .how-to-photo {
        height: 80%;
    }

    .transition-how-to {
        background-size: 80%;
        height: 80%;
    }

    .Vibe h1 {
        font-size: 15vh !important;
    }
}