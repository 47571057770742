:root {
    --black: #00000080;
    --blue: #007bff;
    --dark-blue: #0056b3;
    --darker-blue: #004085;
    --gray: #ddd;
    --light-blue: #007bff80;
    --light-gray: #ccc;
    --white: #fff;
}

.image-modal {
    align-items: center;
    display: flex;
    justify-content: center;
}

.image-modal img {
    left: 10%;
    max-height: 80%;
    max-width: 80%;
    position: absolute;
    top: 10%;
}

.uploader-container {
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--black);
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
    padding: 20px;
    width: 100%;
}

.uploader-error {
    color: var(--red);
    font-size: 16px;
    margin-bottom: 20px;
}

.uploader-image {
    cursor: pointer;
    margin: 10px;
}

.uploader-image {
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--gray);
    cursor: pointer;
    display: flex;
    height: 200px;
    justify-content: center;
    margin: 10px;
    overflow: hidden;
    width: 100%;
}

.uploader-image img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.uploader-images {
    display: grid;
    flex-wrap: wrap;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-content: center;
    max-height: 60vh;
    overflow-y: auto;
}

.uploader-input {
    border: 1px solid var(--gray);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
}

.uploader-input::file-selector-button {
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 16px;
    margin: 20px;
    padding: 10px 20px;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    width: -webkit-fill-available;
}

.uploader-input::file-selector-button:active {
    background-color: var(--darker-blue);
    transform: translateY(0);
}

.uploader-input::file-selector-button:disabled {
    background-color: var(--light-gray);
}

.uploader-input::file-selector-button:focus {
    box-shadow: 0 0 0 3px var(--light-blue);
    outline: none;
}

.uploader-input::file-selector-button:hover {
    background-color: var(--dark-blue);
    transform: translateY(-2px);
}

.uploader-modal {
    align-items: center;
    background-color: var(--black);
    border-radius: 20px;
    display: flex;
    height: auto;
    justify-content: center;
    left: 25%;
    position: absolute;
    top: 10%;
    width: 50%;
}

@media screen and (max-width: 600px) {
    .uploader-modal {
        height: 80%;
        left: 10%;
        top: 10%;
        width: 80%;
    }
}
