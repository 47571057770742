body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Montserrat';
    src:
        local('Montserrat'),
        url(./fonts/static/Montserrat-Regular.ttf) format('truetype');
}
