.App {
    text-align: center;
}

.main {
    background-position: center;
    background-size: cover;
    height: 100%;
    text-align: center;
    width: 100%;
}
