:root {
    --black: #00000080;
    --blue: #007bff;
    --dark-blue: #0056b3;
    --darker-blue: #004085;
    --light-black: #0000001a;
    --light-blue: #007bff80;
    --red: #ff0000;
    --white: #fff;
}

.close-button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 24px;
    justify-content: center;
    line-height: 80%;
    position: absolute;
    right: 0.25em;
    top: 0.25em;
    width: 1em;
}

.close-button:hover {
    background-color: var(--red);
    border-radius: 50%;
    color: var(--white);
}

.modal {
    align-items: center;
    background: var(--black);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.modal-buttons button {
    background-color: var(--blue);
    border: none;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
    margin: 20px;
    padding: 10px 20px;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    width: -webkit-fill-available;
}

.modal-buttons button:active {
    background-color: var(--darker-blue);
    transform: translateY(0);
}

.modal-buttons button:hover {
    background-color: var(--dark-blue);
    transform: translateY(-2px);
}

.modal-buttons button:focus {
    box-shadow: 0 0 0 3px var(--light-blue);
    outline: none;
}

.modal-content {
    max-height: 80vh;
    overflow-y: auto;
}

.modal-dialog {
    background: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--light-black);
    max-width: 90%;
    padding: 20px;
    position: relative;
}

.modal-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
